export default
	props:
		totalCount: Number
		pageSize: Number
		modelValue: Array
	data: ->
		currentPage: 1
	computed:
		pageCount: -> Math.ceil(@totalCount/@pageSize)
		limits: -> [@start, @end]
		start: -> (@currentPage - 1) * @pageSize
		end: -> if @currentPage is @pageCount then @totalCount else @currentPage * @pageSize
	watch:
		totalCount: ->
			if @pageCount < @currentPage
				@changePage(@pageCount)
	methods:
		changePage: (page) ->
			@currentPage = page
			@$emit('update:modelValue', @limits)
