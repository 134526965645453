import moment from 'moment'
import { assessment_memos as config } from '../../data/config.coffee'
import Search from './Search.vue'
import Pagination from './Pagination.vue'
import SiteSelectForm from './SiteSelectForm.vue'
import FlashMessages from '../shared/FlashMessages.vue'
import TwoButtonDialog from '../shared/TwoButtonDialog.vue'
import JsonCSV from '../shared/JsonCSV.vue'

export MailStatus =
	initial: 0
	loading: 1
	imported: 2
	error: 3
	versionError: 4
	forwarded: 5
	bulkError: 6
	mailDiffersError: 7
	catalogVersionError: 8

export sorted = (objects, { key, dir }) ->
	objects.concat().sort (a, b) ->
		a = a[key] or ''
		b = b[key] or ''
		(if a < b then -1 else if a > b then +1 else 0) * dir

matches = (obj, key, val) -> obj[key]?.toString().toLocaleUpperCase().includes val

export default
	components: { Search, Pagination, FlashMessages, JsonCSV }

	data: ->
		data: null
		csvFields:
			[
				'sender',
				'sender_user_email',
				'subject',
				'timestamp',
				'sender_role',
				'shipping_site',
				'catalog',
				'manufacturing_site'
			]
		search:
			text: ''
		sorting:
			key: ''
			dir: +1 # direction, +1 = asc, -1 = desc
		pagination:
			limits: [0, 10]
		MailStatus: MailStatus

	computed:
		mails: ->
			@filteredMails.slice(@pagination.limits...)

		filteredMails: ->
			mails = []
			if @data?.mails then mails = @containingSearchText @filteredBySites @filteredByType @data.mails
			if @sorting.key then mails = sorted mails, @sorting
			mails

		anyLoading: -> @mails.filter((m) -> m.status is MailStatus.loading).length > 0

		isBulkAvailable: -> @anyLoading or @mails.filter((m) -> m.sender_active is true).length is 0

	watch:
		'search.text': -> @resetPagination()

	methods:
		resetPagination: ->
			# Next tick b/c Vue calls the watcher before property computation
			# resulting in a wrong value for pagination.total_count property (which is filteredMails.length)
			@$nextTick -> @$refs.pagination.changePage(1) if @$refs.pagination

		selectSiteAndImport: (mail) ->
			if @notAskingForSites mail
				@importAssessment mail, mail.site_id or @data.sites[0]?.id
			else
				@$vfm.show component: SiteSelectForm, bind:
					sites: @data.sites
					buttonText: @$t('assessment_memos.assessment_memo.import')
					callback: (siteId) =>
						@importAssessment mail, siteId
			return

		bulkImport: ->
			@forEachImportableMail (mail) ->
				mail.statusMessage = null
				mail.status = MailStatus.loading # necessary for illusion of parallel import
			await @forEachImportableMail (mail) =>
				await @importAssessment mail, mail.site_id or @data.sites[0]?.id, no
			return

		forEachImportableMail: (f) ->
			for mail in @mails
				if mail.sender_active
					# note that there will be a problem if the site_id is 0
					if not @notAskingForSites(mail)
						mail.status = MailStatus.bulkError
						mail.statusMessage = { warning: @$t 'assessment_memos.import.error_no_site' }
					if @notAskingForSites(mail) and mail.status isnt MailStatus.imported
						await f(mail)
			return

		importAssessment: (mail, siteId = null, importAnyway = no, updateMailbox = no) ->
			mail.statusMessage = null
			mail.status = MailStatus.loading
			try
				response = await @$http.post config.endpoint + '/import',
					assessment_memo:
						uuid: mail.uuid,
						sender_role: mail.sender_role,
						site: siteId,
						import_anyway: importAnyway,
						update_mailbox: updateMailbox,
				mail.assessmentId = response?.data?.assessment_id
				mail.status = MailStatus.imported
				mail.site_title = @titleOfSite siteId, @data.sites
			catch error
				mail.status = @getErrorStatus(error.response?.data)
				mail.statusMessage = error.response?.data?.flash
				mail.site_id = siteId
			return
		
		closeWarning: (mail) ->
			mail.statusMessage = null
			return

		filteredByType: (mails) ->
			mails.filter (mail) => @isAssessment(mail)

		filteredBySites: (mails) ->
			siteIds = @data.sites.map (el) -> el.id
			mails.filter (mail) -> mail.site_id is null or siteIds.includes mail.site_id

		containingSearchText: (mails) ->
			mails.filter (mail) =>
				s = @search.text.toUpperCase()
				matches(mail, 'sender', s) or
				matches(mail, 'subject', s) or
				matches(mail, 'sender_role', s) or
				matches(mail, 'sender_user_email', s) or
				matches(mail, 'shipping_site', s) or
				matches(mail, 'catalog', s) or
				matches(mail, 'manufacturing_site', s)

		isAssessment: (mail) -> !!mail.sender_role

		arrowType: (key) -> if key is @sorting.key then if @sorting.dir > 0 then 'dn' else 'up' else ''

		sortBy: (key) ->
			if key is @sorting.key
				@sorting.dir = -@sorting.dir
			else
				@sorting.key = key
				@sorting.dir = +1
			return

		normalize: (mail, sites) ->
			mail.status = MailStatus.initial
			mail.statusMessage = null
			mail.site_title = @titleOfSite mail.site_id, sites
			if mail.timestamp
				mail.timestamp = moment(mail.timestamp).format('lll')
			else
				mail.timestamp = @$t('assessment_memos.index.no_timestamp')
			mail.sender = @$t('assessment_memos.index.no_sender_html') unless mail.sender
			mail.subject = @$t('assessment_memos.index.no_subject_html') unless mail.subject
			return

		notAskingForSites: (mail) ->
			return mail.site_id or @data.sites.length is 1

		titleOfSite: (site_id, sites) ->
			if site_id isnt null
				filteredSites = (sites.filter (el) -> el.id is site_id)
				if filteredSites.length then return filteredSites[0].title
			return ''

		deleteMail: (mail) ->
			mail.status = MailStatus.loading
			try
				await @$http.post config.endpoint + '/delete',
					uuid: mail.uuid
			catch error
				flash = error.response?.data?.flash
				mail.status = MailStatus.error
				mail.statusMessage = flash
				return
			index = @data.mails.map((mail) -> return mail.uuid).indexOf(mail.uuid)
			@data.mails.splice(index, 1)
			return

		deleteMailVerification: (mail) ->
			@$vfm.show component: TwoButtonDialog, bind:
				title: @$t('assessment_memos.assessment_memo.delete.title')
				subtitle: @$t('assessment_memos.assessment_memo.delete.subtitle')
				leftButtonText: @$t('assessment_memos.assessment_memo.delete.ok')
				rightButtonText: @$t('assessment_memos.assessment_memo.delete.cancel')
				callback: => @deleteMail mail
			return

		loadData: ->
			@data = null
			try
				response = await @$http.get config.endpoint
				data = response.data
				for mail in data.mails then @normalize mail, data.sites
				@data = data
			catch error
				@data = error.response?.data
			return

		resetData: ->
			@pagination.limits = [0, 10]
			@search.text = ''
			@sorting.key = ''
			@sorting.dir = +1

		refresh: ->
			@resetData()
			@loadData()

		getErrorStatus: (error_body) ->
			if error_body?.incompatible_catalog_versions
				return MailStatus.catalogVersionError
			if error_body?.incompatible_versions
				return MailStatus.versionError
			if error_body?.mail_differs
				return MailStatus.mailDiffersError
			else
				return MailStatus.error

		isDownloadable: (mail) ->
			return @isAssessment(mail) and
				[MailStatus.versionError, MailStatus.initial, MailStatus.error, MailStatus.bulkError].includes(mail.status)

	created: ->
		@loadData()

