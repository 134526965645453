export default
	props:
		modelValue: String
		submitButtonText: type: String, default: 'Search'
		resetHint: type: String, default: 'Reset search'
		inputPlaceholderText: type: String, default: 'Type then press ENTER to search'
	data: ->
		text: @modelValue
	methods:
		submit: ->
			@$emit('update:modelValue', @text)
		reset: ->
			@text = ''
			@submit()
			@$emit('reset')
